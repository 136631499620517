import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/home.vue')
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import('../views/aboutUs.vue')
  },
  {
    path: '/responsibility',
    name: 'responsibility',
    component: () => import('../views/responsibility.vue')
  },
  {
    path: '/Investor',
    name: 'Investor',
    component: () => import('../views/Investor.vue')
  },
  {
    path: '/business',
    name: 'business',
    component: () => import('../views/business.vue')
  },
  {
    path: '/contactUs',
    name: 'contactUs',
    component: () => import('../views/contactUs.vue')
  },
  {
    path: '/journalism',
    name: 'journalism',
    component: () => import('../views/journalism.vue')
  },
  {
    path: '/info',
    name: 'info',
    component: () => import('../views/info.vue')
  }

]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
