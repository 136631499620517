<template>
  <div class="mark">
    <div class="alert">
      <div class="alert-top">
        <img src="https://static.drlianzhuren.com/brandWeb//logo.png" alt="" />
        <div class="error" @click="$emit('setShow')"></div>
      </div>
      <div v-for="(i, index) in item" :key="index">
        <div
          class="footer-item"
          :style="{
            borderTop: index == 0 ? 'solid  0.0186rem #97acd3' : 'none',
          }"
        >
          <span @click="gotoTab(i.route)">{{ i.title }}</span>
          <div
            style="font-size: 0.4444rem; color: #97acd3"
            v-if="index !== 6"
            @click="selectTab(index)"
          >
            <div style="padding: 0.3704rem 0.1481rem">
              <div
                v-if="i.show"
                style="
                  margin-right: 0.07rem;
                  width: 0.21rem;
                  height: 0.03rem;
                  background: #97acd3;
                "
              ></div>
              <div v-else>+</div>
            </div>
          </div>
        </div>
        <div :class="[i.show ? 'tab-animation' : 'reverse-animation']">
          <div
            class="footer-next"
            v-for="(it, index) in i.children"
            :key="index"
            @click="gotoTab(i.route, index)"
          >
            {{ it }}
          </div>
        </div>
      </div>
      <div class="language">
        <div
          @click="seltctlanguage = 1"
          :class="{ seltctlanguage: seltctlanguage == 1 }"
        >
          简体
        </div>
        <div
          @click="seltctlanguage = 2"
          :class="{ seltctlanguage: seltctlanguage == 2 }"
        >
          繁体
        </div>
        <div
          @click="seltctlanguage = 3"
          :class="{ seltctlanguage: seltctlanguage == 3 }"
        >
          EN
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      seltctlanguage: 1,
      item: [
        {
          title: '首页',
          show: false,
          children: ['企业简介', '战略布局', '旗下品牌'],
          route: 'home'
        },
        {
          title: '关于我们',
          show: false,
          children: ['品牌介绍', '里程碑事件', '企业文化', '荣誉奖项'],
          route: 'aboutUs'
        },
        {
          title: '新闻动态',
          show: false,
          children: ['视频报道', '企业动态'],
          route: 'journalism'
        },
        {
          title: '社会责任',
          show: false,
          children: ['疫情公益', '爱心助学', '致敬奋斗者', '书香碧莲盛'],
          route: 'responsibility'
        },
        {
          title: '投资者关系',
          show: false,
          children: ['战略融资', '动脉网估值', '公司治理', '联系方式'],
          route: 'Investor'
        },
        {
          title: '业务模块',
          show: false,
          children: ['植发', '服务保障体系', '养发'],
          route: 'business'
        },
        {
          title: '联系我们',
          show: false,
          route: 'contactUs',
          children: []
        }
      ]
    }
  },
  methods: {
    selectTab (index) {
      if (this.item[index].show) {
        this.item[index].show = false
      } else {
        this.item.forEach((item) => {
          item.show = false
        })
        this.item[index].show = true
      }
    },
    gotoTab (a, b) {
      document.documentElement.scrollTop = 0
      if (this.$route.name === a) {
        b ? this.$emit('setItem', b + 1) : this.$emit('setItem', 1)
        this.$emit('setShow')
      } else {
        this.$emit('setShow')
        this.$router.push({
          name: a,
          params: {
            id: b
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.mark {
  width: 100%;
  height: 100%;
  background: #2e59a7;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  .alert {
    margin: 20px;
  }
  .alert-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
    img {
      width: 90px;
    }
    .error {
      width: 19px;
      height: 19px;

      position: relative;
      cursor: pointer;
    }

    .error::before,
    .error::after {
      content: "";
      position: absolute;
      height: 30px;
      width: 2px;
      top: 0;
      left: 0;
      background: #ffffff;
    }

    .error::before {
      transform: rotate(45deg);
    }

    .error::after {
      transform: rotate(-45deg);
    }
  }
}

.footer-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 19px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #ffffff;
  border-bottom: solid 1px #97acd3;
  span {
    display: block;
    padding: 20px 8px;
  }
}
.language {
  display: flex;
  justify-content: space-between;
  padding: 0 90px;
  margin-top: 70px;
  .seltctlanguage {
    background-color: #97acd3;
    color: #2e59a7;
  }
  > div {
    width: 87px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #97acd3;

    border-radius: 17px;
    border: solid 1px #97acd3;
  }
}

@keyframes Culturetab {
  0% {
    max-height: 0;
  }

  100% {
    max-height: 200px;
  }
}
.tab-animation {
  overflow: hidden;
  animation: Culturetab 0.3s linear both;
}
@keyframes reverseAni {
  0% {
    max-height: 150px;
  }

  100% {
    max-height: 0;
  }
}
.reverse-animation {
  overflow: hidden;
  animation: reverseAni 0s linear both;
}
</style>
